import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { StaticImage } from "gatsby-plugin-image";
import { Link as RouterLink } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import arrowimg from "../../images/pit/arrow.svg";

const ArrowComponent = () => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setHasScrolled(scrollTop > 0);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const breakpoints = useBreakpoint();
  const isMobile = breakpoints.sm;

  const styles = {
    back: {
      position: 'fixed',
      top: isMobile ? 50 : 136,
      left: isMobile ? 5 : 50,
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      zIndex: 100,
      textDecoration: 'none'
    },
    box: {
      position: 'relative',
      width: hovered ? (isMobile ? 'auto' : 150) : (isMobile ? 20 : 28),
      height: isMobile ? 20 : 28,
      background: '#091A3D',
      border: '1px solid #1CE7C2',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      transition: 'width 0.3s',
      overflow: 'hidden'
    },
    text: {
      marginLeft: '10px',
      color: '#fff',
      display: (isMobile || !hovered) ? 'none' : 'block',
      fontWeight: '600',
      fontSize: "15px",
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      flexShrink: 0,
    },
    em: {
      color: '#1ce7c2',
      fontStyle: 'normal',
    }
  };

  return (
    <>
      {loaded &&
        <Box
          component={RouterLink}
          to="/thepit/"
          variant="outlined"
          style={styles.back}
          target="self"
          rel="noreferrer"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <Box sx={styles.box} component="div">
            <Box component="img" src={arrowimg} alt="Arrow" sx={{ width: { xs: 10, md: 14 }, ml: { xs: 0.35, md: 0.7 } }} />
            <Box component="span" sx={styles.text}>Back to <em style={styles.em}>the Pit</em></Box>
          </Box>
        </Box>
      }
    </>
  );
};

export default ArrowComponent;
