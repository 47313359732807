import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout";
import {
  Box,
  Typography,
  Link,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Tooltip,
  Alert,
  Snackbar,
} from "@mui/material";
import { MetaData } from "../../components/common/meta";
import EventTable from "../../components/Agenda";
import { PageProps, useStaticQuery, graphql } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { StaticImage } from "gatsby-plugin-image";
import copy from "copy-to-clipboard";
import ArrowComponent from "../../components/thePit/Arrow";

import Arrow from "../../images/arrow-right.inline.svg";
import zk from "../../images/zkweek/zk.svg";
import bg from "../../images/zkweek/bg.png";
import bg2 from "../../images/zkweek/bg2.svg";
import pit from "../../images/xhack/pit.png";
import sideBrand from "../../images/lineBrand.svg";
import Twitter2 from "../../images/twitter.inline.svg";

import Development from "../../images/zkweek/development.inline.svg";
import Research from "../../images/zkweek/research.inline.svg";

export const card = {
  background: "#0C1F47",
  border: "1px solid #4B608D",
  padding: 1.6,
  aspectRatio: "1/1.1",
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  overflow: "hidden",
  "& h6": {
    color: "#1CE7C2",
    mb: 0.5,
    position: "relative",
  },
  "& a": {
    position: "relative",
    display: "block",
  },
  "& p": {
    fontSize: 10,
    position: "relative",
    "& span": {
      display: "block",
    },
  },
};

export const projectBox = {
  textAlign: "center",
  background: "#091A3D",
  border: "1px solid #4B608D",
  position: "relative",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  height: "105px",
  display: "flex",
  p: 1.5,
  alignItems: "center",
  justifyContent: "center",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#091A3D",
    border: "1px solid #4B608D",
    left: "10px",
    top: "-10px",
    zIndex: -1,
    transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  "&:hover": {
    boxShadow: "0 0 20px 0 rgba(255, 255, 255, .3)",
    "&:before": {
      left: "0",
      top: "0",
    },
  },
};

export const projectBoxLogo = {
  display: "block",
  maxHeight: "44px",
  maxWidth: "110px",
  width: "auto",
};

import vicor from "../../images/zkweek/victor.png";
import julian from "../../images/zkweek/julian.png";
import yannik from "../../images/zkweek/yannik.png";
import jon from "../../images/zkweek/jon.png";
import albert from "../../images/zkweek/albert.png";
import ismael from "../../images/zkweek/ismael.png";
import jorrit from "../../images/zkweek/jorrit.png";
import wang from "../../images/zkweek/wang.png";
import zajac from "../../images/zkweek/zajac.png";
import arun from "../../images/zkweek/arun.png";
import cooper from "../../images/zkweek/cooper.png";
import evan2 from "../../images/zkweek/evan.png";
import manmit from "../../images/zkweek/manmit.png";
import mason from "../../images/zkweek/mason.png";
import arya from "../../images/zkweek/arya.png";
import nathan from "../../images/zkweek/nathan.png";
import daniele from "../../images/zkweek/daniele.png";
import hamid from "../../images/zkweek/hamid.png";
import mikhail from "../../images/zkweek/mikhail.png";
import swati from "../../images/zkweek/swati.png";
import tomer from "../../images/zkweek/tomer.png";
import anton from "../../images/zkweek/anton.png";
import john from "../../images/zkweek/john.png";
import robert from "../../images/zkweek/robert.png";

import manta from "../../images/zkweek/manta.svg";
import elusiv from "../../images/zkweek/elusiv.svg";
import veridise from "../../images/zkweek/veridise.svg";
import nethermind from "../../images/zkweek/nethermind.svg";
import near from "../../images/zkweek/near.svg";
import axiom from "../../images/zkweek/axiom.svg";
import lagrange from "../../images/zkweek/lafgange.svg";
import lightprotocol from "../../images/zkweek/light.svg";
import offchainlabs from "../../images/zkweek/ocl.svg";
import linea from "../../images/zkweek/linea.svg";
import mantle from "../../images/zkweek/mantle.svg";
import mina from "../../images/zkweek/mina.svg";
import scroll from "../../images/zkweek/scroll.svg";
import starkware from "../../images/zkweek/starkware.svg";
import zpoken from "../../images/zkweek/zpoken.svg";
import risczero from "../../images/zkweek/risczero.svg";
import amd from "../../images/zkweek/amd.svg";
import ingonyama from "../../images/zkweek/ingonyama.svg";
import nil from "../../images/zkweek/nil.svg";
import lurk from "../../images/zkweek/lurk.svg";
import ottersec from "../../images/zkweek/ottersec.svg";
import kadena from "../../images/zkweek/kadena.svg";

import csongor from "../../images/zkweek/csongor.png";
import emanuele from "../../images/zkweek/emanuele.png";
import kaveh from "../../images/zkweek/kaveh.png";
import rahul from "../../images/zkweek/rahul.png";
import saeed from "../../images/zkweek/saeed.png";

const projects = [
  {
    logo: amd,
    url: "https://www.amd.com/",
  },
  {
    logo: axiom,
    url: "https://axiom.xyz/",
  },
  {
    logo: elusiv,
    url: "https://elusiv.io/",
  },
  {
    logo: ingonyama,
    url: "https://www.ingonyama.com/",
  },
  {
    logo: kadena,
    url: "https://kadena.io/",
  },
  {
    logo: lagrange,
    url: "https://www.lagrange.dev/",
  },
  {
    logo: lightprotocol,
    url: "https://www.lightprotocol.com/",
  },
  {
    logo: linea,
    url: "https://linea.build/",
  },
  {
    logo: lurk,
    url: "https://lurk-lab.com/",
  },
  {
    logo: manta,
    url: "https://manta.network/",
  },
  {
    logo: mantle,
    url: "https://www.mantle.xyz/",
  },
  {
    logo: mina,
    url: "https://minaprotocol.com/",
  },
  {
    logo: near,
    url: "https://near.org/",
  },
  {
    logo: nethermind,
    url: "https://nethermind.io/",
  },
  {
    logo: nil,
    url: "https://nil.foundation/",
  },
  {
    logo: offchainlabs,
    url: "https://offchainlabs.com/",
  },
  {
    logo: ottersec,
    url: "https://osec.io/",
  },
  {
    logo: risczero,
    url: "https://www.risczero.com/",
  },
  {
    logo: scroll,
    url: "https://scroll.io/",
  },

  {
    logo: starkware,
    url: "https://starkware.co/",
  },

  {
    logo: veridise,
    url: "https://veridise.com/",
  },
  {
    logo: zpoken,
    url: "https://zpoken.io/",
  },
];

const speakers = [
  {
    image: albert,
    name: "Albert Garreta",
    company: ["Blockchain and Cryptography Researcher at Nethermind"],
    twitter: null,
  },
  {
    image: anton,
    name: "Anton Yezhov",
    company: ["Head of Engineering at Zpoken"],
    twitter: null,
  },
  {
    image: arun,
    name: "Arun Devabhaktuni",
    company: ["Mantle"],
    twitter: null,
  },
  {
    image: arya,
    name: "Arya Pourtabatabaie",
    company: ["Senior Backend Engineer at Consensys"],
    twitter: null,
  },
  {
    image: cooper,
    name: "Cooper Midroni",
    company: ["Product/Growth", "at Mantle"],
    twitter: null,
  },
  {
    image: daniele,
    name: "Daniele Pinna",
    company: ["Head of Research and Investment at 0VIX"],
    twitter: null,
  },
  {
    image: evan2,
    name: "Evan Shapiro",
    company: ["CEO", "at Mina Protocol"],
    twitter: null,
  },
  {
    image: hamid,
    name: "Hamid Salehi",
    company: ["Director of Product Management at AMD"],
    twitter: null,
  },
  {
    image: ismael,
    name: "Ismael Hishon-Rezaizade",
    company: ["Co-founder and CEO", "at Lagrange Labs"],
    twitter: null,
  },
  {
    image: john,
    name: "John Burnham",
    company: ["CEO at Lurk Lab"],
    twitter: null,
  },
  {
    image: jon,
    name: "Jon Stephens",
    company: ["Chief Technology", "Officer at Veridise"],
    twitter: null,
  },
  {
    image: wang,
    name: "Jonathan Wang",
    company: ["Co-founder", "at Axiom"],
    twitter: null,
  },
  {
    image: jorrit,
    name: "Jorrit Palfner",
    company: ["Co-founder and CEO", "at Light Protocol"],
    twitter: null,
  },
  {
    image: julian,
    name: "Julian Deschler",
    company: ["Co-founder", "at Elusiv"],
    twitter: null,
  },
  {
    image: manmit,
    name: "Manmit Singh",
    company: ["Starkware Developer", "Advocate"],
    twitter: null,
  },
  {
    image: mason,
    name: "Mason Liang",
    company: ["Software Engineer ", "at Scroll"],
    twitter: null,
  },
  {
    image: zajac,
    name: "Michal Zajac",
    company: ["Head of Cryptography Research ", "at Nethermind"],
    twitter: null,
  },
  {
    image: mikhail,
    name: "Mikhail Komarov",
    company: ["Founder at =nil; Foundation"],
    twitter: null,
  },
  {
    image: nathan,
    name: "Nathan Holland",
    company: ["Protocol Engineer at O(1) Labs"],
    twitter: null,
  },
  {
    image: robert,
    name: "Robert Chen",
    company: ["Founder at Otter Sec"],
    twitter: null,
  },
  {
    image: swati,
    name: "Swati Gupta",
    company: ["Sr Product Manager at AMD"],
    twitter: null,
  },
  {
    image: tomer,
    name: "Tomer Solberg",
    company: ["Principal Researcher at Ingonyama"],
    twitter: null,
  },
  {
    image: vicor,
    name: "Victor Ji",
    company: ["Co-founder at", "Manta Network"],
    twitter: null,
  },

  {
    image: yannik,
    name: "Yannik Schrade",
    company: ["Co-founder", "at Elusiv"],
    twitter: null,
  },
];

const colab = [
  {
    image: csongor,
    name: "Csongor Kiss",
    company: ["Jump"],
    twitter: null,
  },
  {
    image: emanuele,
    name: "Emanuele Cesena",
    company: ["Jump"],
    twitter: null,
  },
  {
    image: kaveh,
    name: "Kaveh Aasaraai",
    company: ["Jump"],
    twitter: null,
  },
  {
    image: rahul,
    name: "Rahul Maganti",
    company: ["Jump"],
    twitter: null,
  },
  {
    image: saeed,
    name: "Saeed Badreg",
    company: ["Jump"],
    twitter: null,
  },
];

const zkweek = ({ location }: PageProps) => {
  const [open, setOpen] = useState(false);

  //console.log(eventData);
  return (
    <Layout>
      <MetaData title="zkWeek in the Pit" location={location} type="website" />
      <ArrowComponent></ArrowComponent>
      <Box
        component="img"
        src={bg}
        alt=""
        sx={{
          display: "block",
          top: { xs: "80px", md: "100px" },
          transform: "scale(1.1)",
          position: "absolute",
          left: 0,
          width: "100%",
        }}
      />
      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "200px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          display: { xs: "none", xl: "block" },
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />

      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="div"
          sx={{
            maxWidth: 750,
            mx: "auto",
            mt: { xs: 12, md: 20 },
            mb: { xs: 6, md: 16 },
            textAlign: "center",
            zIndex: 2,
            position: "relative",
          }}
        >
          <Box
            component="img"
            sx={{ width: "85%", maxWidth: "328px" }}
            src={zk}
            alt="zkWeek"
            title="zkWeek"
          />
        </Box>
        <Box
          component="div"
          sx={{
            position: "relative",
            "&:before": {
              content: `url(${sideBrand})`,
              position: "absolute",
              left: 40,
              top: -80,
              display: { xs: "none", xl: "block" },
              zIndex: -1,
            },
          }}
        />
        <Box
          component="div"
          sx={{
            maxWidth: 1086,
            mx: "auto",
            display: { md: "flex" },
          }}
        >
          <Box
            component="div"
            sx={{
              textAlign: "center",
              width: "100%",
              maxWidth: { md: "320px" },
              flex: "0 0 auto",
              zIndex: 2,
            }}
          >
            <Box
              component="div"
              sx={{
                background: "#0C1F47",
                border: "1px solid #4B608D",
                p: 2,
                minHeight: "82px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                mb: "10px",
              }}
            >
              <Typography variant="h5">
                <Box component="span" sx={{ color: " #08FBCF" }}>
                  May 15 - 20,
                </Box>{" "}
                2023
              </Typography>
            </Box>
            <Box
              component="div"
              sx={{
                background: "#0C1F47",
                border: "1px solid #4B608D",
                p: 2,
                minHeight: "82px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5">
                <Box
                  component="span"
                  sx={{ color: " #08FBCF", display: "block" }}
                >
                  the Pit
                </Box>{" "}
                <Link
                  component={OutboundLink}
                  target="_blank"
                  href="https://goo.gl/maps/YCkN1rRB2yXnAJh56"
                  rel="noreferrer"
                  sx={{
                    fontWeight: 400,
                    fontSize: 14,
                    textDecoration: "underline",
                  }}
                >
                  600 W Chicago Ave, Chicago, IL
                </Link>
              </Typography>
            </Box>
          </Box>

          <Box
            component="div"
            sx={{
              background: "#0C1F47",
              border: "1px solid #4B608D",
              py: { xs: 3, md: 4 },
              px: { xs: 3, md: 7 },
              flex: 1,
              mt: { xs: "10px", md: 0 },
              position: "relative",
              ml: { xs: 0, md: "10px" },
            }}
          >
            <StaticImage
              src="../../images/zkweek/bg3.svg"
              alt="Introducing the Pit."
              placeholder="blurred"
              layout="fullWidth"
              objectFit="cover"
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
              }}
            />
            <Typography sx={{ mb: 2 }}>
              This spring, the Pit is bringing together ZK researchers,
              academics, and protocols from around the world for a full week of
              shoulder-to-shoulder collaboration in Chicago.
            </Typography>
            <Typography sx={{ mb: 3 }}>
              Over 6 days, we’ll discuss and address the largest challenges
              facing Web3 today and answer the most important questions
              surrounding zero-knowledge. Don’t miss your chance to attend this
              industry-shaping event.
            </Typography>
            <Typography sx={{ fontWeight: "bold" }}>
              Applications for this event are now closed.
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        component="div"
        sx={{
          position: "relative",
          pt: { xs: 8, md: 16 },
          pb: { xs: 4, md: 12 },
        }}
      >
        <Box component="div" sx={{ maxWidth: 1086, mx: "auto" }}>
          <Typography variant="h2">
            Who’s{" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              attending
            </Box>
          </Typography>
          <Typography sx={{ maxWidth: 406, mt: 2 }}>
            Here are a few of our ecosystem's researchers and academics
            gathering in Chicago for zkWeek.
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            maxWidth: 1082,
            mx: "auto",
            mt: 3.2,
            mb: 5,
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              xs2: "repeat(2, 1fr)",
              sm: "repeat(3, 1fr)",
              md: "repeat(5, 1fr)",
            },
            gap: "14px",
          }}
        >
          {speakers.map((s) => (
            <Box component="div" key={s.name} sx={card}>
              {s.image && (
                <Box
                  component="img"
                  src={s.image}
                  alt={s.name}
                  sx={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    objectFit: "contain",
                    width: { xs: "70%", sm: "initial" },
                  }}
                />
              )}
              <Box component="div">
                <Typography variant="h6">{s.name}</Typography>
                <Typography>
                  {s.company.map((c, index) => (
                    <Box key={index} component="span">
                      {c}
                    </Box>
                  ))}
                </Typography>
              </Box>
              {s.twitter && (
                <Link href={s.twitter} target="_blank" rel="noreferrer">
                  <Twitter2 />
                </Link>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          position: "relative",
          pb: { xs: 4, md: 13 },
        }}
      >
        <Box component="div" sx={{ maxWidth: 1086, mx: "auto" }}>
          <Typography variant="h2">
            Collaborate with{" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              Jump
            </Box>
          </Typography>
          <Typography sx={{ maxWidth: 466, mt: 2 }}>
            Expect to connect with Jump developers, researchers, and thinkers
            for an entire week of collaboration.
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            maxWidth: 1082,
            mx: "auto",
            mt: 3.2,
            mb: 5,
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              xs2: "repeat(2, 1fr)",
              sm: "repeat(3, 1fr)",
              md: "repeat(5, 1fr)",
            },
            gap: "14px",
          }}
        >
          {colab.map((s) => (
            <Box component="div" key={s.name} sx={card}>
              {s.image && (
                <Box
                  component="img"
                  src={s.image}
                  alt={s.name}
                  sx={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    objectFit: "contain",
                    width: { xs: "70%", sm: "initial" },
                  }}
                />
              )}
              <Box component="div">
                <Typography variant="h6">{s.name}</Typography>
                <Typography>
                  {s.company.map((c, index) => (
                    <Box key={index} component="span">
                      {c}
                    </Box>
                  ))}
                </Typography>
              </Box>
              {s.twitter && (
                <Link href={s.twitter} target="_blank" rel="noreferrer">
                  <Twitter2 />
                </Link>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      <Box component="div" sx={{ position: "relative" }}>
        <Box component="div" sx={{ maxWidth: 1086, mx: "auto" }}>
          <Typography variant="h2" sx={{ mb: 2 }}>
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              zk
            </Box>
            Ecosystem
          </Typography>
          <Typography>
            Explore the protocols gathering in Chicago for zkWeek.
          </Typography>
          <Box
            component="div"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              mt: 5,
              ml: "-10px",
            }}
          >
            {projects.map((item) => (
              <Box
                component="div"
                key={item.url}
                sx={{
                  width: { xs: "50%", md: "33.33%", lg: "20%" },
                  maxWidth: { lg: "240px" },
                  px: "10px",
                  mb: 4,
                }}
              >
                <Link
                  sx={projectBox}
                  component={OutboundLink}
                  href={item.url}
                  target="_blank"
                >
                  <Box
                    component="img"
                    src={item.logo}
                    sx={projectBoxLogo}
                    loading="lazy"
                  />
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          component="img"
          src={bg2}
          alt=""
          sx={{
            display: "block",
            position: "absolute",
            width: { xs: "100%", md: "initial" },
            right: "-15px",
            bottom: "-100px",
            zIndex: -1,
            opacity: 0.5,
          }}
        />
      </Box>

      <Box
        component="div"
        sx={{
          maxWidth: 1086,
          mx: "auto",
          pt: { xs: 10, md: 12 },
          pb: { xs: 5, md: 5 },
        }}
      >
        <Typography variant="h2">
          <Box component="span" sx={{ color: "#1CE7C2" }}>
            zk
          </Box>
          Week at a Glance
        </Typography>
        <Box
          component="div"
          sx={{
            color: "#1CE7C2",
            display: "flex",
            justifyContent: "flex-end",
            mt: "50px",
            gap: "24px",
          }}
        >
          <Box
            component="span"
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <Research /> Research
          </Box>
          <Box
            component="span"
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <Development /> Development
          </Box>
        </Box>
        <Box component="div" sx={{ width: "100%", overflow: "auto", pb: 2 }}>
          <Table
            sx={{
              mt: 2,
              minWidth: "800px",
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: "36px 10px", width: "33.33%" }}>
                  <Typography sx={{ color: "#1CE7C2" }}>
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        justifyContent: "center",
                      }}
                    >
                      <Research /> Proof System Components
                    </Box>
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: "36px 10px", width: "33.33%" }}>
                  <Typography sx={{ color: "#1CE7C2" }}>
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        justifyContent: "center",
                      }}
                    >
                      <Research /> Hardware Acceleration Not for Dummies
                    </Box>
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: "36px 10px", width: "33.33%" }}>
                  <Typography sx={{ color: "#1CE7C2" }}>
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        justifyContent: "center",
                      }}
                    >
                      <Development /> Real Talk on Virtual Machines
                    </Box>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Rollups</TableCell>
                <TableCell>FPGAs / ASICS</TableCell>
                <TableCell>ZK EVMs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Bridges</TableCell>
                <TableCell>GPUs</TableCell>
                <TableCell>Risc Based Architectures</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Oracles</TableCell>
                <TableCell>Security</TableCell>
                <TableCell>zkNetworking Event and Dinner</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box
          component="div"
          sx={{ width: "100%", overflow: "auto", pb: { xs: 5, md: 10 } }}
        >
          <Table
            sx={{
              mt: "32px",
              minWidth: "800px",
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell sx={{ padding: "36px 16px", width: "33.33%" }}>
                  <Typography sx={{ color: "#1CE7C2" }}>
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        justifyContent: "center",
                      }}
                    >
                      <Development /> Laying the ZK Groundwork
                    </Box>
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: "36px 16px", width: "33.33%" }}>
                  <Typography sx={{ color: "#1CE7C2" }}>
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        justifyContent: "center",
                      }}
                    >
                      <Development /> Nutz and Boltz of ZK Development
                    </Box>
                  </Typography>
                </TableCell>
                <TableCell sx={{ padding: "36px 16px", width: "33.33%" }}>
                  <Typography sx={{ color: "#1CE7C2" }}>
                    Event Wrap-Up and Final Presentations
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Lookup Arguments</TableCell>
                <TableCell>Compilers and Precompiles</TableCell>
                <TableCell>Closing Party</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Arithmetization Schemes</TableCell>
                <TableCell>Proving Infrastructure</TableCell>
                <TableCell>Final Presentations</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Languages</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>

      <StaticImage
        src="../../images/index/lines.svg"
        alt=""
        placeholder="blurred"
        style={{
          position: "absolute",
          right: "0",
          bottom: "80px",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
    </Layout>
  );
};

export default zkweek;
